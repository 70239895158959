import styled from "@emotion/styled";
import { Container, Row, Col } from "react-bootstrap";

export const Section = styled(Container)`
  margin-top: 75px;
  margin-bottom: 75px;
`;

export const SingleRow = styled(Row)``;

export const SingleVenture = styled(Col)`
  padding: 20px !important;
  cursor: pointer;
`;

export const VentureDetails = styled(Col)``;

export const LogoContainer = styled.div`
  border: 2px solid;
  border-radius: 15px;
  padding: 67.5px 60px;
  border-color: #370d6f;
  cursor: pointer;
  transition: 0.5s;
  position: relative;
  overflow: hidden;

  svg {
    opacity: 1;
    margin: 0 auto;
    height: 80px;
    width: 95%;
    display: block;
    object-fit: contain;
  }

  &.wide-logo {
    svg {
      max-height: 100px;
      max-width: 65%;
    }
  }

  &.extra-wide-logo {
    svg {
      max-height: 120px;
      width: 100%;
    }
  }

  &.narrow-logo {
    svg {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }

  &.slim-logo {
    svg {
      max-width: 55%;
    }
  }

  @media (max-width: 980px) {
    padding: 40px 30px;
  }

  @media (max-width: 767px) {
    padding: 50px 50px;
    svg {
      max-width: 180px;
      margin: 0 auto;
      display: block;
    }
  }
`;

export const Logo = styled.img``;

export const DetailsContainer = styled.div`
  margin-bottom: 25px;
  padding-left: 10px;
  padding-right: 10px;
`;

export const DetailsTitle = styled.h3`
  font-family: MuliBold;
`;

export const DetailsDescription = styled.p`
  font-family: MuliRegular;
  line-height: 24px;
  color: grey;
  margin-top: 15px;
`;
