import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import {
  Button,
  ButtonContainer,
  Description,
  DetailsContainer,
  Link,
  Page,
} from "./styled";
import Navigation from "../components/navigation";
import DoubleColumn from "../components/double-column";
import CollapsibleList from "../components/collapsible-list";
import { ReactComponent as CamillaPihlCosmeticsLogo } from "../../assets/img/camilla_pihl_cosmetics_logo.svg";
import { ReactComponent as GlodLogo } from "../../assets/img/glod_logo.svg";
import { ReactComponent as CamillaPihlLogo } from "../../assets/img/camillapihl_logo.svg";
import { ReactComponent as IslandRumCompany } from "../../assets/img/island_rum_company_logo.svg";
import { ReactComponent as BikefinderLogo } from "../../assets/img/bikefinder_logo.svg";
import { ReactComponent as AtelieLogo } from "../../assets/img/atelie_logo.svg";
import { ReactComponent as AtelierCamillaPihl } from "../../assets/img/atelier_camilla_pihl.svg";
import { ReactComponent as MadconLogo } from "../../assets/img/madcon_logo.svg";
import { ReactComponent as CLMDLogo } from "../../assets/img/clmd_logo.svg";
import { ReactComponent as JourneysLogo } from "../../assets/img/journeys_logo.svg";
import { ReactComponent as SkogluftLogo } from "../../assets/img/skogluft_logo.svg";
import { ReactComponent as RechargeHealth } from "../../assets/img/recharge_health_logo.svg";
import Footer from "../components/footer";
import Scroller from "react-scroll";
import { useLocation } from "react-router-dom";

const Index = () => {
  const [activeView, setActiveView] = useState({ id: null });

  const location = useLocation();
  const scroller = Scroller.scroller;
  const scroll = Scroller.animateScroll;
  console.log(location);

  useEffect(() => {
    if (location.hash === "#portfolio") {
      scroller.scrollTo("portfolio", {
        duration: 1500,
        offset: -100,
      });
    }
    if (location.hash === "#top") {
      scroll.scrollToTop();
    }
  }, [location, scroller, scroll]);

  const ventures = [
    {
      id: 5,
      logo: <CamillaPihlLogo />,
      title: "Camilla Pihl Fashion Group AS",
      tag: "camilla-pihl",
      type: "wide-logo",
      date: "18.01.2020",
      content: (
        <DetailsContainer>
          <Description>
            A contemporary fashion brand that creates Timeless, Cool,
            Aspirational and Essential pieces. The company is a joint venture
            with Holzweiler Group AS.
          </Description>
          <ButtonContainer>
            <Link target="_blank" href="https://camillapihl.com/">
              <Button className="left">Go to website</Button>
            </Link>
            <Link
              target="_blank"
              href="https://proff.no/selskap/camilla-pihl-fashion-group-as/lysaker/kl%C3%A6r/IF8LO3W08XE/"
            >
              <Button className="right">Business insights</Button>
            </Link>
          </ButtonContainer>
        </DetailsContainer>
      ),
    },
    {
      id: 1,
      logo: <MadconLogo />,
      title: "Icon Group AS (Madcon)",
      tag: "madcon",
      type: "narrow-logo",
      date: "18.01.2020",
      content: (
        <DetailsContainer>
          <Description>
            A music company group for the international success act Madcon. The
            company group is a joint venture with Squzo Invest (Yosef
            Woldemariam) and Conquest (Tshawe Baqwa). The companies are Madcon
            Live AS, Icon Recordings AS and Icon Group AS.
          </Description>
          <ButtonContainer>
            <Link target="_blank" href="http://www.madconlive.com/">
              <Button className="left">Go to website</Button>
            </Link>
            <Link
              target="_blank"
              href="https://proff.no/selskap/icon-recordings-as/oslo/lydproduksjon-og-utstyr/IGISYI407SG/"
            >
              <Button className="right">Icon Recordings</Button>
            </Link>
            <Link
              target="_blank"
              href="https://proff.no/selskap/icon-group-as/oslo/hovedkontortjenester/IGIS17R10NZ/"
            >
              <Button className="left">Icon Group</Button>
            </Link>
            <Link
              target="_blank"
              href="https://www.proff.no/selskap/icon-live-as/oslo/underholdning-og-arrangørtjenester/IGISYGH07QQ/"
            >
              <Button className="right">Icon Live</Button>
            </Link>
          </ButtonContainer>
        </DetailsContainer>
      ),
    },
    {
      id: 2,
      logo: <CamillaPihlCosmeticsLogo />,
      title: "Camilla Pihl Cosmetics",
      tag: "camilla-pihl-cosmetics",
      type: "extra-wide-logo",
      date: "18.01.2020",
      content: (
        <DetailsContainer>
          <Description>
            Camilla Pihl Cosmetics is an award-winning Norwegian beauty brand
            with high quality and innovative formulas. All formulas are 100%
            cruelty free & 100% lovable. The brand is a joint venture with Elle
            Basic AS / The Feelgood Company AS.
          </Description>
          <ButtonContainer>
            <Link target="_blank" href="https://camillapihlcosmetics.com/">
              <Button className="left">Go to website</Button>
            </Link>
          </ButtonContainer>
        </DetailsContainer>
      ),
    },
    {
      id: 3,
      logo: <CLMDLogo />,
      title: "Trouble Music AS (CLMD)",
      tag: "clmd",
      type: "wide-logo",
      date: "18.01.2020",
      content: (
        <DetailsContainer>
          <Description>
            CLMD is a leading DJ, artist and producer based in Oslo Norway. In
            partnership with manager and attorney Jørgen Keiserud, and Universal
            Music, we handle all aspects of the CLMD project.
          </Description>
          <ButtonContainer>
            <Link
              target="_blank"
              href="https://open.spotify.com/artist/7LiVklVR1325BIOqD9kykr"
            >
              <Button className="left">Listen on Spotify</Button>
            </Link>
            <Link
              target="_blank"
              href="https://proff.no/selskap/trouble-music-as/oslo/lydproduksjon-og-utstyr/IF8PK7V07SG/"
            >
              <Button className="right">Business insights</Button>
            </Link>
          </ButtonContainer>
        </DetailsContainer>
      ),
    },
    {
      id: 10,
      logo: <AtelierCamillaPihl />,
      title: "Atelier Camilla Pihl",
      tag: "atelier-camilla-pihl",
      type: "slim-logo",
      date: "18.01.2020",
      content: (
        <DetailsContainer>
          <Description>
            An exclusive collaboration with Kid Interior and Hemtex.
          </Description>
          <ButtonContainer>
            <Link
              target="_blank"
              href="https://www.kid.no/inspirasjon/kolleksjoner/ateliercamillapihl?utm_source=camillapihl&utm_medium=social&utm_campaign=atelier&utm_term=branding&utm_content=organic"
            >
              <Button className="left">Go to website</Button>
            </Link>
            <Link
              target="_blank"
              href="https://www.hemtex.se/inspiration/kollektioner/atelier"
            >
              <Button className="left">Go to website</Button>
            </Link>
          </ButtonContainer>
        </DetailsContainer>
      ),
    },
    {
      id: 4,
      logo: <GlodLogo />,
      title: "Glöd Sophie Elise",
      tag: "glod-sophie-elise",
      type: "narrow-logo",
      date: "18.01.2020",
      content: (
        <DetailsContainer>
          <Description>
            An award-winning cosmetic brand, market leader in the selftan
            category in Norway for 2021, cofounded and fronted by social media
            phenomenon Sophie Elise. The brand is a joint venture with Sophie
            Elise AS and Elle Basic AS / The Feelgood Company AS.
          </Description>
          <ButtonContainer>
            <Link target="_blank" href="https://www.vita.no/glod-sophie-elise">
              <Button className="left">Go to website</Button>
            </Link>
          </ButtonContainer>
        </DetailsContainer>
      ),
    },
    {
      id: 6,
      logo: <IslandRumCompany />,
      title: "The Island Rum Company AS",
      tag: "island-rum-company",
      type: "wide-logo",
      date: "18.01.2020",
      content: (
        <DetailsContainer>
          <Description>
            {" "}
            A spirit company that is building a portfolio of Cuban rum products.
            PPV are part of the founding partners, now minority owners.
          </Description>
          <ButtonContainer>
            <Link target="_blank" href="https://www.blacktears.com/">
              <Button className="left">Go to website</Button>
            </Link>
            <Link target="_blank" href="https://ronlaprogresiva.com/">
              <Button className="right">Go to website</Button>
            </Link>
            <Link
              target="_blank"
              href="https://proff.no/selskap/the-island-rum-company-as/oslo/vin-øl-og-brennevin/IGILHOS0ENI/"
            >
              <Button className="left">Business insights</Button>
            </Link>
          </ButtonContainer>
        </DetailsContainer>
      ),
    },
    {
      id: 7,
      logo: <AtelieLogo />,
      title: "Atelier AS",
      tag: "atelie",
      type: "wide-logo",
      date: "18.01.2020",
      content: (
        <DetailsContainer>
          <Description>
            Atelie provides art enthusiasts with access to the wide diversity of
            quality art created in artists' studios.
          </Description>
          <ButtonContainer>
            <Link target="_blank" href="https://atelie.art/">
              <Button className="left">Go to website</Button>
            </Link>
          </ButtonContainer>
        </DetailsContainer>
      ),
    },
    {
      id: 8,
      logo: <BikefinderLogo />,
      title: "Bikefinder AS",
      tag: "bikefinder",
      type: "wide-logo",
      date: "18.01.2020",
      content: (
        <DetailsContainer>
          <Description>
            A tracking company that specializes in finding lost things. PPV are
            early investors, minority owners.
          </Description>
          <ButtonContainer>
            <Link target="_blank" href="https://bikefinder.com/u-k/">
              <Button className="left">Go to website</Button>
            </Link>
          </ButtonContainer>
        </DetailsContainer>
      ),
    },
    {
      id: 9,
      logo: <SkogluftLogo />,
      title: "Skogluft AS",
      tag: "skogluft",
      type: "slim-logo",
      date: "22.05.2023",
      content: (
        <DetailsContainer>
          <Description>
            A company that specializes in bringing nature into offices and
            homes. PPV are early investors, minority owners.
          </Description>
          <ButtonContainer>
            <Link target="_blank" href="https://www.skogluft.com/en-no">
              <Button className="left">Go to website</Button>
            </Link>
          </ButtonContainer>
        </DetailsContainer>
      ),
    },
    {
      id: 11,
      logo: <JourneysLogo />,
      title: "Journeys Travel AS",
      tag: "journeys",
      type: "wide-logo",
      date: "18.01.2020",
      content: (
        <DetailsContainer>
          <Description>
            A boutique agency specializing in luxurious and hidden gems. PPV are
            part of the founding partners.
          </Description>
          <ButtonContainer>
            <Link target="_blank" href="https://journeys.no/">
              <Button className="left">Go to website</Button>
            </Link>
          </ButtonContainer>
        </DetailsContainer>
      ),
    },
    {
      id: 12,
      logo: <RechargeHealth />,
      title: "Recharge Health",
      tag: "recharge-health",
      type: "wide-logo",
      date: "14.07.2023",
      content: (
        <DetailsContainer>
          <Description>
            Introducing FlexBeam, a wearable infrared therapy device. A new
            scientific breakthrough technology trusted by 15,000+ users
            globally.
          </Description>
          <ButtonContainer>
            <Link target="_blank" href="https://recharge.health/">
              <Button className="left">Go to website</Button>
            </Link>
          </ButtonContainer>
        </DetailsContainer>
      ),
    },
  ];

  return (
    <Page>
      <Helmet>
        <title>Peters & Pihl Ventures - Welcome</title>
        <meta name="description" content=""></meta>
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:url" content="https://www.ppventures.no/" />
        <meta name="twitter:title" content="Peters & Pihl Ventures - Welcome" />
        <meta
          name="twitter:description"
          content="Peters & Pihl Ventures is a boutique venture company situated where commerce meets culture. The founders are Camilla Pihl and Peter Peters – life and business partners for more than 20 years."
        />
        <meta name="twitter:image" content="" />

        <meta property="og:url" content="https://www.karevold.no/" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Peters & Pihl Ventures - Welcome" />
        <meta
          property="og:description"
          content="Peters & Pihl Ventures is a boutique venture company situated where commerce meets culture. The founders are Camilla Pihl and Peter Peters – life and business partners for more than 20 years."
        />
        <meta property="og:image" content="" />
      </Helmet>
      <Navigation />
      <DoubleColumn isFrontpage={true} />
      <CollapsibleList
        list={ventures}
        activeView={activeView}
        setActiveView={setActiveView}
      />
      <Footer />
    </Page>
  );
};

export default Index;
