import styled from "@emotion/styled";
import { Col, Row } from "react-bootstrap";

export const Section = styled.section`
  max-width: 1200px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
  margin-top: 100px;
`;

export const SingleRow = styled(Row)`
  margin-top: 50px;
`;

export const TextCol = styled(Col)`
  max-width: 550px;
  @media (max-width: 850px) {
    max-width: unset;
  }
`;

export const TextContainer = styled.div``;

export const ButtonContainer = styled.div``;

export const InsightButton = styled.button`
  font-family: MuliBold;
  margin-right: 15px;
  border: 1px solid #370d6f;
  padding-bottom: 10px;
  padding-top: 10px;
  padding-left: 17.5px;
  padding-right: 17.5px;
  border-radius: 14px;
  background-color: transparent;
  color: #370d6f;
  font-size: 14px;
  transition: 0.5s;
  margin-bottom: 10px;
  &:hover {
    transform: scale(1.025);
  }
`;

export const Title = styled.h1`
  font-family: MuliBlack;
  .weight {
    color: #370d6f;
  }
`;

export const Text = styled.p`
  font-family: MuliRegular;
  line-height: 24px;
  color: grey;
  margin-top: 25px;
`;

export const Link = styled.a``;

export const Button = styled.button`
  margin: 0 auto;
  margin-top: 25px;
  padding-bottom: 15px;
  padding-top: 15px;
  padding-left: 40px;
  padding-right: 40px;
  border-radius: 15px;
  background-color: #370d6f;
  border: none;
  color: #fff;
  font-family: MuliBlack;
  transition: 0.5s;
  &:hover {
    transform: scale(1.025);
  }
`;

export const IllustrationCol = styled(Col)``;

export const IllustrationContainer = styled.div`
  position: relative;
`;

export const IllustrationLightbulb = styled.img`
  position: absolute;
  top: 0;
  left: 45%;
  right: 50%;
  margin: auto;
  z-index: 4;
  height: 100px;
  transform: rotate(7deg);
  animation-delay: 1.5s;
  animation: float 4s ease-in-out infinite;

  @media (max-width: 1000px) {
    left: 45%;
  }

  @media (max-width: 776px) {
    height: 11vw;
    left: 43%;
  }

  @keyframes float {
    0% {
      transform: translatey(0px);
    }
    50% {
      transform: translatey(0px);
    }
    65% {
      transform: translatey(-15px);
    }
    75% {
      transform: translatey(0px);
    }
    100% {
      transform: translatey(0px);
    }
  }
`;

export const NodesIllustration = styled.img`
  width: 100%;
  margin-top: 75px;
  margin-bottom: 75px;
  padding-left: 25px;

  @media (max-width: 767px) {
    padding-left: 0px !important;
    margin-top: 25px;
    margin-bottom: 25px;
  }
`;

export const IllustrationAnimation = styled.div`
  width: 100%;
  float: right;
  text-align: center;
  svg {
    display: block;
    margin: 0 auto;
    fill: red !important;
  }
  @media (max-width: 767px) {
    svg {
      width: 100%;
      max-height: 375px;
      display: block;
      margin: 0 auto;
    }
  }
`;
