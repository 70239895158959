import { Collapse } from "react-collapse";
import {
  DetailsContainer,
  DetailsDescription,
  DetailsTitle,
  LogoContainer,
  Section,
  SingleRow,
  SingleVenture,
} from "./styled";
import { useMediaQuery } from "react-responsive";

const Index = ({ list, activeView, setActiveView }) => {
  const isMobileView = useMediaQuery({ query: "(max-width: 767px)" });

  const editedIndex = list.indexOf(
    list.filter((item) => activeView && item.id === activeView.id)[0]
  );

  const indexRemain = editedIndex % 3;

  const newIndex =
    !activeView.id || editedIndex === -1
      ? list.length + 1
      : editedIndex + (3 - indexRemain);

  const handleSelect = (venture) => {
    console.log(venture, activeView.id);
    if (venture.id === activeView.id) {
      setActiveView({ id: null });
    } else {
      setActiveView(venture);
    }
  };

  return (
    <Section name="portfolio">
      {!isMobileView ? (
        <>
          <SingleRow>
            {list.slice(0, newIndex).map((venture) => {
              return (
                <SingleVenture
                  onClick={() => handleSelect(venture)}
                  sm={12}
                  md={4}
                >
                  <LogoContainer className={`${venture.type} ${venture.tag}`}>
                    {venture.logo}
                  </LogoContainer>
                </SingleVenture>
              );
            })}
          </SingleRow>
          {activeView && activeView.id && (
            <Collapse isOpened={activeView}>
              <DetailsContainer>
                <DetailsTitle>{activeView.title}</DetailsTitle>
                <DetailsDescription>{activeView.content}</DetailsDescription>
              </DetailsContainer>
            </Collapse>
          )}
          {list.length >= newIndex && (
            <SingleRow>
              {list.slice(newIndex, list.length).map((venture) => {
                return (
                  <SingleVenture
                    sm={12}
                    md={4}
                    onClick={() => handleSelect(venture)}
                  >
                    <LogoContainer className={`${venture.type} ${venture.tag}`}>
                      {venture.logo}
                    </LogoContainer>
                  </SingleVenture>
                );
              })}
            </SingleRow>
          )}
        </>
      ) : (
        <>
          <SingleRow>
            {list.map((venture) => {
              return (
                <>
                  <SingleVenture
                    onClick={() => setActiveView(venture)}
                    sm={12}
                    md={4}
                  >
                    <LogoContainer className={`${venture.type} ${venture.tag}`}>
                      {venture.logo}
                    </LogoContainer>
                  </SingleVenture>
                  {activeView.id === venture.id && (
                    <Collapse isOpened={activeView}>
                      <DetailsContainer>
                        <DetailsTitle>{activeView.title}</DetailsTitle>
                        <DetailsDescription>
                          {activeView.content}
                        </DetailsDescription>
                      </DetailsContainer>
                    </Collapse>
                  )}
                </>
              );
            })}
          </SingleRow>
        </>
      )}
    </Section>
  );
};

export default Index;
