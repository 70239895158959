import styled from "@emotion/styled";
import { Container } from "react-bootstrap";

export const Section = styled(Container)`
  max-width: 1200px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 50px;
  margin-top: 50px;
`;

export const Title = styled.h2`
  text-align: center;
  font-family: MuliBlack;
`;

export const Text = styled.p`
  text-align: center;
  max-width: 400px;
  margin: 0 auto;
  font-family: MuliRegular;
  line-height: 24px;
  color: grey;
`;

export const ButtonLink = styled.a`
  text-decoration: none;
`;

export const Button = styled.button`
  margin: 0 auto;
  display: block;
  margin-top: 25px;
  padding-bottom: 15px;
  padding-top: 15px;
  padding-left: 40px;
  padding-right: 40px;
  border-radius: 15px;
  background-color: #370d6f;
  border: none;
  color: #fff;
  font-family: MuliBlack;
  transition: 0.5s;
  &:hover {
    transform: scale(1.025);
  }
`;

export const CopyrightWrapper = styled.div`
  text-align: center;
  margin-top: 100px;
`;

export const CopyrightText = styled.p`
  font-family: MuliRegular;
  font-size: 14px;
`;

export const CreditsText = styled.p`
  font-family: MuliRegular;
  color: grey;
  font-size: 12px;
`;

export const CreditsLink = styled.a`
  color: #370d6f;
  text-decoration: none;
`;
