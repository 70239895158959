import styled from "@emotion/styled";
import { Navbar, Container, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";

export const NavigationBar = styled(Navbar)`
  position: fixed;
  width: 100%;
  top: 0px;
  z-index: 100;
  background-color: #fff;
`;

export const NavigationContainer = styled(Container)`
  max-width: 1310px;
  padding-left: 15px;
  padding-right: 15px;
`;

export const BrandLink = styled(Link)`
  text-decoration: none;
`;

export const NavigationBrand = styled(Navbar.Brand)`
  font-family: Inter;
  font-size: 20px;
  cursor: pointer;
  svg {
    width: 90px;
    height: 50px;
    margin-top: -5px;
    margin-right: 5px;
  }
`;

export const NavigationIcon = styled.img`
  max-width: 40px;
  margin-right: 10px;
`;

export const NavigationToggle = styled(Navbar.Toggle)`
  border: none;
  padding: 0px;
  outline: none !important;
  border: none !important;
  svg {
    outline: none !important;
    fill: #370d6f !important;
    height: 40px;
    width: 40px;
  }
`;

export const NavigationCollapsed = styled(Navbar.Collapse)``;

export const Navigation = styled(Nav)`
  padding-top: 10px;
`;

export const NavigationLink = styled(Nav.Link)`
  font-family: MuliBold;
  font-size: 16px;
  color: #370d6f;
  &:hover {
    color: #370d6f;
    transform: scale(1.025);
  }
  &.active {
    color: #370d6f !important;
  }
  @media (max-width: 776px) {
    text-align: center !important;
  }
`;

export const ContactLink = styled.a`
  font-family: MuliBold;
  font-size: 16px;
  margin-top: 7.5px;
  margin-left: 5px;
  text-decoration: none;
  color: #370d6f;
  transition: 0.5s;
  &:hover {
    color: #370d6f;
  }
  @media (max-width: 776px) {
    text-align: center !important;
  }
`;
