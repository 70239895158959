import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as Logo } from "../../../assets/img/logo.svg";
import { ReactComponent as Menu } from "../../../assets/img/menu.svg";
import Scroller from "react-scroll";
import {
  ContactLink,
  Navigation,
  NavigationBar,
  NavigationBrand,
  NavigationCollapsed,
  NavigationContainer,
  NavigationLink,
  NavigationToggle,
} from "./styled";

const Index = () => {
  const scroller = Scroller.scroller;
  const scroll = Scroller.animateScroll;
  const location = useLocation();
  const navigate = useNavigate();

  const clickPortfolio = () => {
    if (location.pathname === "/") {
      scroller.scrollTo("portfolio", {
        duration: 1500,
        offset: -100,
      });
      document.getElementById("navToggle").click();
    } else {
      navigate("/#portfolio");
    }
  };

  const handleLogoClick = () => {
    if (location.pathname === "/") {
      scroll.scrollToTop({ duration: 100, delay: 10 });
    } else {
      navigate("/#top");
    }
  };

  return (
    <NavigationBar expand="md" collapseOnSelect>
      <NavigationContainer>
        <NavigationBrand onClick={handleLogoClick}>
          <Logo />
        </NavigationBrand>
        <NavigationToggle id="navToggle" aria-controls="responsiveNav">
          <Menu />
        </NavigationToggle>
        <NavigationCollapsed id="responsiveNav">
          <Navigation className="ms-auto">
            <NavigationLink
              className={`${location.pathname === "/" ? "active" : ""}`}
              onClick={clickPortfolio}
            >
              Portfolio
            </NavigationLink>
            <NavigationLink
              className={`${location.pathname === "/about" ? "active" : ""}`}
              href="/about"
            >
              About us
            </NavigationLink>
            <ContactLink href="mailto:peter.peters@ppventures.no">
              Contact
            </ContactLink>
          </Navigation>
        </NavigationCollapsed>
      </NavigationContainer>
    </NavigationBar>
  );
};

export default Index;
