import { Page } from "./styled";
import Navigation from "../components/navigation";
import DoubleColumn from "../components/double-column";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

const Index = () => {
  return (
    <Page>
      <Helmet>
        <title>Peters & Pihl Ventures - Welcome</title>
        <meta name="description" content=""></meta>
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:url" content="https://www.ppventures.no/" />
        <meta name="twitter:title" content="Peters & Pihl Ventures - Welcome" />
        <meta name="twitter:description" content="" />
        <meta name="twitter:image" content="" />

        <meta property="og:url" content="https://www.karevold.no/" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Peters & Pihl Ventures - Welcome" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
      </Helmet>
      <Navigation />
      <DoubleColumn isFrontpage={false} />
      <Footer />
    </Page>
  );
};

export default Index;
