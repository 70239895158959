import styled from "@emotion/styled";

export const Page = styled.div``;

export const DetailsContainer = styled.div``;

export const Description = styled.p`
  font-family: MuliRegular;
`;

export const ButtonContainer = styled.div`
  display: block;
  position: relative;
  margin-top: 25px;
  margin-bottom: 20px;
`;

export const Link = styled.a`
  text-decoration: none;
`;

export const Button = styled.button`
  font-family: MuliBold;
  border: 1px solid #370d6f;
  padding-bottom: 10px;
  padding-top: 10px;
  padding-left: 17.5px;
  padding-right: 17.5px;
  border-radius: 14px;
  margin-right: 15px;
  background-color: transparent;
  color: #370d6f;
  font-size: 14px;
  transition: 0.5s;
  margin-bottom: 10px;
  &:hover {
    transform: scale(1.025);
  }

  @media (max-width: 800px) {
    width: 48%;
    margin-right: 0px;
    &.left {
      margin-right: 2%;
    }
    &.right {
      margin-left: 2%;
    }
  }
`;
