import { useLottie } from "lottie-react";
import {
  Button,
  IllustrationAnimation,
  IllustrationCol,
  IllustrationContainer,
  Section,
  SingleRow,
  TextCol,
  Text,
  TextContainer,
  Title,
  IllustrationLightbulb,
  NodesIllustration,
  ButtonContainer,
  InsightButton,
  Link,
} from "./styled";
import lightbulb from "../../../assets/img/lightbulb.svg";
import frontpageAnimation from "../../../assets/animations/panda.json";
import contactAnimation from "../../../assets/animations/contact.json";
import nodesMap from "../../../assets/img/nodes_map.svg";
import Scroll from "react-scroll";

const Index = ({ isFrontpage }) => {
  const options = {
    animationData: isFrontpage ? frontpageAnimation : contactAnimation,
    loop: true,
  };

  const scroller = Scroll.scroller;
  const { View } = useLottie(options);

  const clickPortfolio = () => {
    scroller.scrollTo("portfolio", {
      duration: 1500,
      offset: -100,
    });
  };

  if (isFrontpage) {
    return (
      <Section>
        <SingleRow>
          <TextCol
            xs={{ span: 12, order: 2 }}
            md={{ span: 6, order: 1 }}
            className="my-auto"
          >
            <TextContainer>
              <Title>
                We are Camilla Pihl and Peter Peters - and we’re invested in{" "}
                <span className="weight">good ideas</span>.
              </Title>
              <Text>
                We create, build, and partner with outstanding companies on
                projects where commerce intersects with culture. Our passion
                lies in consistently crafting innovative and enhanced solutions
                that brighten everyday life.
              </Text>
              <Button onClick={clickPortfolio}>Portfolio</Button>
            </TextContainer>
          </TextCol>
          <IllustrationCol
            xs={{ span: 12, order: 1 }}
            md={{ span: 6, order: 2 }}
            className="my-auto"
          >
            <IllustrationContainer>
              <IllustrationLightbulb src={lightbulb} />
              <IllustrationAnimation>{View}</IllustrationAnimation>
            </IllustrationContainer>
          </IllustrationCol>
        </SingleRow>
      </Section>
    );
  }
  return (
    <Section>
      <SingleRow>
        <TextCol xs={12} md={5} className="my-auto">
          <TextContainer>
            <Title>About us</Title>
            <Text>
              Peters & Pihl Ventures is a boutique venture company positioned at
              the intersection of commerce and culture. Its founders, Camilla
              Pihl and Peter Peters, have been life and business partners for
              over two decades. While the saying goes "don't do business with
              friends" and "never do business with family," they chose to take a
              different path. PPV is a family-run enterprise that primarily
              engages in business with the friends and family of Camilla and
              Peter, and they wholeheartedly embrace this approach. They
              wouldn't have it any other way.
            </Text>
          </TextContainer>
          <ButtonContainer>
            <Link href="https://proff.no/selskap/peters-pihl-ventures-as/oslo/underholdning-og-arrang%C3%B8rtjenester/IF3SK9C07QQ/">
              <InsightButton>Peters & Pihl Ventures AS</InsightButton>
            </Link>
            <Link href="https://proff.no/selskap/peters-pihl-holding-as/oslo/bedriftsr%C3%A5dgivning/IGHYBU1043Z/">
              <InsightButton>Peters & Pihl Holding AS</InsightButton>
            </Link>
            <Link href="https://www.proff.no/selskap/camilla-pihl-fashion-group-as/lysaker/klær/IF8LO3W08XE/">
              <InsightButton>Camilla Pihl Fashion Group AS</InsightButton>
            </Link>
          </ButtonContainer>
        </TextCol>
        <IllustrationCol xs={12} md={7}>
          <IllustrationContainer>
            <IllustrationAnimation>
              <NodesIllustration src={nodesMap} />
            </IllustrationAnimation>
          </IllustrationContainer>
        </IllustrationCol>
      </SingleRow>
    </Section>
  );
};

export default Index;
