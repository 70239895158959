import {
  Button,
  ButtonLink,
  CopyrightText,
  CopyrightWrapper,
  CreditsLink,
  CreditsText,
  Section,
  Text,
  Title,
} from "./styled";

const Index = () => {
  return (
    <Section>
      <Title>Got any questions?</Title>
      <Text>
        If you want to get in touch with us or want to tell us about your
        project - we’d love to hear from you!
      </Text>
      <ButtonLink href="mailto:peter.peters@ppventures.no">
        <Button>Get in touch</Button>
      </ButtonLink>
      <CopyrightWrapper>
        <CopyrightText>
          Copyright 2022 - Peters & Pihl Ventures AS
        </CopyrightText>
        <CreditsText>
          Development & design by{" "}
          <CreditsLink
            target="_blank"
            href="https://www.linkedin.com/in/andreasskaalerud/"
          >
            Andreas Skaalerud
          </CreditsLink>
        </CreditsText>
      </CopyrightWrapper>
    </Section>
  );
};

export default Index;
