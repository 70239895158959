import Home from "./pages/index";
import About from "./pages/about";
import NotFound from "./pages/not-found";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";

const Index = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<Home />} />
        <Route exact path="/about" element={<About />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Index;
